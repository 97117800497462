<template>
  <div class="foot_menu w-full">
    <Tabbar v-model="active" active-color="#FE4B4C" inactive-color="#999999" :border="false" @change="changeTab">
      <TabbarItem
          v-for="(item, index) in menuList"
          :key="index"
      >
        <span>{{ item.title }}</span>
        <template #icon="props">
          <img :src="props.active ? item.sel_pic : item.pic"/>
        </template>
      </TabbarItem>
    </Tabbar>
  </div>
</template>

<script>
import axios from "../net/axios";
import api from "../net/api";
import UserStorage from "../utils/user_storage";
import {Icon, Tabbar, TabbarItem} from "vant";
import {mapState, mapActions} from "vuex";

export default {
  props: ['pageKey'],
  components: {
    Icon,
    Tabbar,
    TabbarItem,
  },
  data() {
    return {
      active: "首页",
      menuList: [
        {
          "title": "首页",
          "isShow": 1,
          "url": "/home",
          "pic": require('@/assets/img/nav/menu_home.png'),
          "sel_pic": require('@/assets/img/nav/menu_home_checked.png'),
        },
        {
          "title": "领取黑金卡",
          "isShow": 1,
          "url": "/blackGoldCard",
          "pic": require('@/assets/img/nav/menu_zxbt.png'),
          "sel_pic": require('@/assets/img/nav/menu_zxbt_checked.png'),
        },
        // {
        //   "title": "环保金",
        //   "isShow": 1,
        //   "url": "/environmentalFund",
        //   "pic": require('@/assets/img/nav/menu_zhzj.png'),
        //   "sel_pic": require('@/assets/img/nav/menu_zhzj_checked.png'),
        // },
        // {
        //   "title": "投资中心",
        //   "isShow": 1,
        //   "url": "/investCentral",
        //   "pic": require('@/assets/img/nav/menu_invite_c.png'),
        //   "sel_pic": require('@/assets/img/nav/menu_invite_c_checked.png'),
        // },
        // {
        //   "title": "困难群众",
        //   "isShow": 1,
        //   "url": "/specialSubsidies",
        //   "pic": require('@/assets/img/nav/menu_zxbt.png'),
        //   "sel_pic": require('@/assets/img/nav/menu_zxbt_checked.png'),
        // },
        // {
        //   "title": "资环钱包",
        //   "isShow": 1,
        //   "url": "/envWallet",
        //   "pic": require('@/assets/img/nav/menu_zxbt.png'),
        //   "sel_pic": require('@/assets/img/nav/menu_zxbt_checked.png'),
        // },

        // {
        //   "title": "合伙人",
        //   "isShow": 1,
        //   "url": "/benefits",
        //   "pic": require('@/assets/img/nav/menu_zxbt.png'),
        //   "sel_pic": require('@/assets/img/nav/menu_zxbt_checked.png'),
        // },
        {
          "title": "一期恢复",
          "isShow": 1,
          "url": "/applyRecovery",
          "pic": require('@/assets/img/nav/menu_invite_c.png'),
          "sel_pic": require('@/assets/img/nav/menu_invite_c_checked.png'),
        },
        {
          "title": "产品",
          "isShow": 1,
          "url": "/product",
          "pic": require('@/assets/img/nav/menu_zhzx.png'),
          "sel_pic": require('@/assets/img/nav/menu_zhzx_checked.png'),
        },

        // {
        //   "title": "在线客服",
        //   "isShow": 1,
        //   "url": this.$config.KF_URL,
        //   "pic": require('@/assets/img/nav/menu_kf.png'),
        //   "sel_pic": require('@/assets/img/nav/menu_kf_checked.png'),
        // },
        {
          "title": "我的",
          "isShow": 1,
          "url": "/profile",
          "pic": require('@/assets/img/nav/menu_my.png'),
          "sel_pic": require('@/assets/img/nav/menu_my_checked.png'),
        }],
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  created() {
    this.$bus.$on('active', this.handleMessage);
  },
  beforeDestroy() {
    this.$bus.$off('active', this.handleMessage); // 在组件销毁前移除监听器
  },
  mounted() {
    console.log('加载页面')
    this.init();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    handleMessage(index) {
      console.log('收到事件', index)
      this.active = index;
    },
    matchRoute(menuPath, routePath) {
      // 处理首页特殊情况
      if (menuPath === '/home' && routePath === '/') return true;
      return routePath.startsWith(menuPath);
    },
    init() {
      const currentPath = this.$route.path;
      const activeIndex = this.menuList.findIndex(item =>
        this.matchRoute(item.url, currentPath)
      );
      if (activeIndex !== -1) {
        this.active = activeIndex;
      }
    },
    async changeTab(index) {
      this.active = index;
      const item = this.menuList[index];
      if (item.title == '在线客服') {
        window.location.href = item.url;
        return;
      }
      if (item.title === '领取黑金卡') {
        try {
          await this.getUserInfo();
          if (this.userInfo && this.userInfo.is_member) {
            this.navigateTo('/getBlackGoldCards');
          } else {
            this.navigateTo('/applyReturn');
          }
        } catch (error) {
          console.error('Failed to get user info:', error);
          this.navigateTo('/applyReturn');
        }
        return;
      }
      this.navigateTo(item.url);
    },
    navigateTo(url) {
      this.$router.push(url);
    },
    //获取导航菜单
    getNavigation() {
      axios
          .get(api.navList)
          .then((res) => {
            const data = res.data;
            let list = [];
            data.map((item) => {
              if (item.isShow) {
                list.push(item);
              }
            });
            console.log("菜单列表", list);
            this.menuList = list;
            UserStorage.setNavList(list);
          })
          .catch((err) => {
            err.msg && this.$toast(err.msg);
          });
    },
  },
  watch: {
    $route: {
      handler(to) {
        const activeIndex = this.menuList.findIndex(item => item.url === to.path);
        if (activeIndex !== -1) {
          this.active = activeIndex;
        }
      },
      immediate: true
    }
  },
};
</script>

<style lang="less" scoped>
.foot_menu {
  height: 50px;
  background-color: #fff;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
}

.van-tabbar-item__icon img {
  height: 1.5rem;
}
</style>
