<template>
  <div class="flex flex-col h-screen">
    <!-- loading遮罩 -->
    <van-overlay
      :show="pageLoading"
      class="flex items-center justify-center"
    >
      <van-loading
        type="spinner"
        color="#fff"
        size="36"
        vertical
      >加载中...</van-loading>
    </van-overlay>

    <!-- 标题栏 -->
    <div
      class="h-[44px] flex items-center px-4 relative"
      style="background: radial-gradient(352.65% 128.18% at 21.33% -5.63%, #FE462D 0%, #FF867D 58.53%, #F52735 100%);"
    >
      <van-icon
        name="arrow-left"
        class="text-white"
        @click="onClickLeft"
      />
      <span class="text-white text-lg absolute left-1/2 -translate-x-1/2">超级银联卡资产恢复</span>
    </div>

    <!-- 内容区域 -->
    <div
      class="flex-1 overflow-y-auto pb-16"
      style="background: linear-gradient(180deg, #FF4D4F 0%, #FFE0E0 100%);"
    >
      <div class="p-4">
        <!-- Content container -->
        <div class="bg-white rounded-lg p-4">
          <!-- About section -->
          <div class="mb-6">
            <h2 class="text-center text-xl mb-4">关于</h2>
            <p class="text-normal leading-6 text-[#575757] px-2">
              因中国资源循环集团有限公司一期用户人数以及不法分子攻击导致，现正式升级为中国资源循环集团有限公司针对一期已认购银联卡用户进行恢复返还！恢复返还期间请耐心等待，将在1-15个工作日内将您认购的银联卡金额返还本人银行卡内！！
            </p>
          </div>

          <!-- Bank cards section -->
          <div class="mb-6">
            <img
              class="w-full"
              :src="require('@/assets/img/bank-group.png')"
              alt="银行卡类型展示"
            />
          </div>

          <!-- Submit button -->
          <van-button
            block
            class="mt-4"
            :color="userInfo.apply_coin_return === 1 ? '#FFB800' : '#FF4D4F'"
            :disabled="userInfo.apply_coin_return === 1"
            @click="submitRecoveryApplication"
          >{{ userInfo.apply_coin_return === 1 ? '正在返还' : '申请返还' }}
          </van-button>
        </div>
      </div>
    </div>

    <!-- 添加支付弹窗组件 -->
    <PaymentDialog
      v-model:show="showPayment"
      :amount="299"
      @confirm="handlePayConfirm"
    />
  </div>
</template>

  <script>
import { Field, Button, Loading, Overlay, Dialog } from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import { mapActions, mapState } from "vuex";
import PaymentDialog from "@/components/common/PaymentDialog.vue";
import {isNullOrEmpty} from "@/utils";

export default {
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
    PaymentDialog,
  },
  data() {
    return {
      pageLoading: false,
      showPayment: false,
    };
  },
  mounted() {
    this.getUserInfo();
  },
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    // 添加缺失的方法
    onClickLeft() {
      this.$router.go(-1);
    },
    async submitRecoveryApplication() {
      if (!this.userInfo?.is_identify) {
        this.$dialog.confirm({
          title: '提示',
          message: '请先实名认证',
          confirmButtonText: '去认证',
          cancelButtonText: '取消'
        }).then(() => {
          this.$router.push({name: 'realNameAuth'})
        });
        return;
      }
      // 检查是否为会员
      if (!this.userInfo.is_member) {
        Dialog.alert({
          message: "请先成为会员后再申请返还",
        }).then(() => {
          this.showPayment = true;
        });
        return;
      }

      try {
        this.pageLoading = true;
        await axios.post(api.apply_coin_return);
        Dialog.alert({
          message: "申请提交成功，请耐心等待处理",
        }).then(() => {
          this.getUserInfo();
        });
      } catch (error) {
        Dialog.alert({
          message: error.msg || "申请提交失败，请稍后重试",
        });
      } finally {
        this.pageLoading = false;
      }
    },

    async handlePayConfirm({ payType }) {
      try {
        const params = {
          source: 6,
          pay_type: payType,
          amount: 299,
        };

        const res = await axios.post(api.balancePay, params);
        this.showPayment = false;

        if (payType === 8) {
          await Dialog.alert({
            title: "提示",
            message: "升级成功！",
            theme: "round-button",
            confirmButtonText: "确定",
            confirmButtonColor: "#F52735",
          });
          this.getUserInfo();
          return;
        }

        if (res.data?.payUrl) {
          window.location.href = res.data.payUrl;
        }
      } catch (err) {
        this.$toast(err.msg || "支付失败");
      }
    },
  },
};
</script>

  <style scoped lang="less">
</style>
