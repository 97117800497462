<template>
  <div class="flex flex-col h-screen bg-[#F7F7F7]">
    <!-- loading遮罩 -->
    <van-overlay
      :show="pageLoading"
      class="flex items-center justify-center"
    >
      <van-loading
        type="spinner"
        color="#fff"
        size="36"
        vertical
      >加载中...</van-loading>
    </van-overlay>

    <!-- 标题栏 -->
    <div
      class="h-[44px] flex items-center justify-center px-4"
      style="background: radial-gradient(352.65% 128.18% at 21.33% -5.63%, #FE462D 0%, #FF867D 58.53%, #F52735 100%);"
    >
      <span class="text-white text-lg">申请恢复</span>
    </div>
    <!-- 内容区域 -->
    <div class="flex-1 overflow-y-auto pb-[50px]">
      <!-- 用户信息 -->
      <div class="p-[16px] ">
        <div class="flex items-center mb-[16px]">
          <div class="w-[44px] h-[44px] rounded-full overflow-hidden">
            <img
              class="w-full h-full object-cover"
              :src="userInfo.avatar || require('@/assets/img/d-avatar.png')"
              alt=""
            >
          </div>
          <div class="ml-[12px]">
            <p class="text-[16px] text-[#313131] leading-[22px]">{{ userInfo.name|maskPhoneNumber }}</p>
            <p class="text-[12px] text-[#B59F9D] mt-[2px]">邀请码：{{ userInfo.code }}</p>
          </div>
        </div>

        <!-- 原始股卡片 -->
        <div class="stock-card bg-[#FFF1F0] rounded-[8px] p-[16px] mb-[12px] relative">
          <div class="flex justify-between">
            <div>
              <p class="text-[16px] font-medium text-[#313131] mb-[12px]">我的原始股</p>
              <p class="text-[14px] text-[#9A2E24] font-[PingFang-SC] leading-normal">持有数量</p>
              <p class="text-[20px] text-[#9A2E24] font-[PingFang-SC] leading-normal mt-[1px]">{{ originalStock }}</p>
            </div>
            <!--            <div class="flex flex-col items-end">-->
            <!--              <van-button size="small" type="danger" class="h-[28px] min-w-[64px] mb-[8px]" @click="handleWithdraw">提现</van-button>-->
            <!--              <van-button size="small" class="h-[28px] min-w-[64px] detail-btn" @click="viewDetails">明细</van-button>-->
            <!--            </div>-->
          </div>
        </div>

        <!-- 资产信息卡片组 -->
        <div class="grid grid-cols-2 gap-[12px] mb-[12px]">
          <div class="bg-white rounded-[8px] p-[16px]">
            <p class="text-[14px] text-[#666666]">国家分红</p>
            <p class="text-[16px] text-[#FF4D4F] mt-[4px]">¥{{ nationalDividend }}</p>
            <span class="frozen-tag">冻结</span>
          </div>
          <div class="bg-white rounded-[8px] p-[16px]">
            <p class="text-[14px] text-[#666666]">资源分红</p>
            <p class="text-[16px] text-[#FF4D4F] mt-[4px]">¥{{ resourceDividend }}</p>
            <span class="frozen-tag">冻结</span>
          </div>
          <div class="bg-white rounded-[8px] p-[16px]">
            <p class="text-[14px] text-[#666666]">投资金</p>
            <p class="text-[16px] text-[#FF4D4F] mt-[4px]">¥{{ investment }}</p>
            <span class="frozen-tag">冻结</span>
          </div>
          <div class="bg-white rounded-[8px] p-[16px]">
            <p class="text-[14px] text-[#666666]">资源补贴</p>
            <p class="text-[16px] text-[#FF4D4F] mt-[4px]">¥{{ resourceSubsidy }}</p>
            <span class="frozen-tag">冻结</span>
          </div>
          <div class="bg-white rounded-[8px] p-[16px]">
            <p class="text-[14px] text-[#666666]">投资金额</p>
            <p class="text-[16px] text-[#FF4D4F] mt-[4px]">¥{{ totalInvestment }}</p>
          </div>
          <div class="bg-white rounded-[8px] p-[16px]">
            <p class="text-[14px] text-[#666666]">每日分红提现</p>
            <p class="text-[16px] text-[#FF4D4F] mt-[4px]">¥{{ dailyWithdrawal }}</p>
          </div>
        </div>

        <!-- 公告 -->
        <div class="bg-white rounded-[8px] p-[16px] mb-[16px]">
          <p class="text-[19px] text-[#575757] leading-[22px] text-center font-normal font-['PingFang_SC']">公告</p>
          <p class="text-[14px] text-[#666666] leading-[22px]">
            因一期用户人数太多，以及不法分子恶意攻击。免费晋级一期资产，一期资产恢复完成申请后，将在1-15个工作日完成恢复资产，已提现的将会到账本人绑定银行卡。因资产恢复时间较长，请耐心等待资产恢复。
          </p>
        </div>

        <!-- 申请按钮 -->
        <van-button
          type="danger"
          block
          class="h-[44px] text-[16px] font-medium rounded-[22px]"
          @click="handleApplyRecovery"
        >
          {{ userInfo.apply_coin_restore === 1 ? '审核中' : '申请恢复' }}
        </van-button>
      </div>
    </div>

    <!-- 添加支付对话框组件 -->
    <PaymentDialog
      v-model="showPayment"
      :amount="299"
      @confirm="handlePayConfirm"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { Field, Button, Loading, Overlay, Dialog } from "vant";
import axios from "@/net/axios";
import api from "@/net/api";
import PaymentDialog from "@/components/common/PaymentDialog.vue";

export default {
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
    PaymentDialog,
  },
  data() {
    return {
      pageLoading: false,
      originalStock: "80000.00",
      nationalDividend: "35193411.00",
      resourceDividend: "289.20",
      investment: "130000.00",
      resourceSubsidy: "20000.00",
      totalInvestment: "130000.00",
      dailyWithdrawal: "20000.00",
      showPayment: false,
    };
  },
  filters: {
    maskPhoneNumber(phoneNumber) {
      if (!phoneNumber) return "";
      return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
    },
  },
  mounted() {
    this.getUserInfo();
  },
  computed: {
    ...mapState("user", ["userInfo"]),
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    // 添加缺失的方法
    onClickLeft() {
      this.$router.go(-1);
    },
    handleWithdraw() {
      this.$router.push("/withdraw");
    },
    viewDetails() {
      this.$router.push("/transaction-details");
    },
    handleApplyRecovery() {
      if (this.userInfo?.apply_coin_restore === 1) {
        Dialog.alert({
          title: '提示',
          message: '3-10工作日恢复到帐，如有疑问请联系客服',
          confirmButtonText: '我知道了'
        });
        return;
      }

      if (!this.userInfo?.is_identify) {
        this.$dialog
          .confirm({
            title: "提示",
            message: "请先实名认证",
            confirmButtonText: "去认证",
            cancelButtonText: "取消",
          })
          .then(() => {
            this.$router.push({ name: "realNameAuth" });
          });
        return;
      }
      // 检查是否为会员
      if (!this.userInfo.is_member) {
        Dialog.alert({
          message: "请先成为会员后再申请恢复",
        }).then(() => {
          this.showPayment = true;
        });
        return;
      }
      Dialog.confirm({
        title: "确认申请",
        message: "确定要申请恢复资产吗？",
      })
        .then(() => {
          this.submitRecoveryApplication();
        })
        .catch(() => {
          // Handle cancellation
        });
    },
    async submitRecoveryApplication() {
      try {
        this.pageLoading = true;
        await axios.post(api.apply_coin_restore);
        Dialog.alert({
          message: "申请提交成功，请耐心等待处理",
        }).then(() => {
          this.getUserInfo();
        });
      } catch (error) {
        Dialog.alert({
          message: error.msg || "申请提交失败，请稍后重试",
        });
      } finally {
        this.pageLoading = false;
      }
    },
    async handlePayConfirm({ payType }) {
      try {
        const params = {
          source: 6,
          pay_type: payType,
          amount: 299,
        };

        const res = await axios.post(api.balancePay, params);
        this.showPayment = false;

        if (payType === 8) {
          await Dialog.alert({
            title: "提示",
            message: "升级成功！",
            theme: "round-button",
            confirmButtonText: "确定",
            confirmButtonColor: "#F52735",
          });
          this.getUserInfo();
          return;
        }

        if (res.data?.payUrl) {
          window.location.href = res.data.payUrl;
        }
      } catch (err) {
        this.$toast(err.msg || "支付失败");
      }
    },
  },
};
</script>

<style scoped lang="less">
.stock-card {
  background-image: url("~@/assets/img/stock-bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  .van-button {
    font-family: "PingFang SC";
    font-size: 14px;
    font-weight: 400;
  }

  .detail-btn {
    background: #a96464;
    border-radius: 4px;
    border: none;
    color: #fff;
  }
}

.van-button {
  &--danger {
    background: linear-gradient(to right, #ff4d4f, #ff7875);
    border: none;

    &.van-button--plain {
      background: transparent;
      border: 1px solid #ff4d4f;
      color: #ff4d4f;
    }
  }
}

:deep(.van-dialog) {
  border-radius: 8px;
  overflow: hidden;

  .van-dialog__header {
    padding-top: 20px;
    font-weight: 500;
    font-size: 16px;
    color: #313131;
  }

  .van-dialog__message {
    padding: 20px;
    font-size: 14px;
    color: #666666;
    line-height: 22px;
  }

  .van-dialog__footer {
    padding: 8px 16px 16px;

    .van-button {
      height: 44px;
      font-size: 16px;
      font-weight: 500;
      border-radius: 22px;
    }
  }
}

.frozen-tag {
  display: inline-block;
  padding: 2px 8px;
  margin-top: 4px;
  border-radius: 5px;
  background: #9f9f9f;
  color: #ffffff;
  font-size: 12px;
  line-height: 17px;
  font-family: "PingFang SC";
}
</style>
