<template>
  <div class="flex flex-col h-screen">
    <!-- loading遮罩 -->
    <van-overlay
      :show="pageLoading"
      class="flex items-center justify-center"
    >
      <van-loading
        type="spinner"
        color="#fff"
        size="36"
        vertical
      >加载中...
      </van-loading>
    </van-overlay>

    <!-- 标题栏 -->
    <div
      class="h-[44px] flex items-center  justify-center px-4"
      style="background: radial-gradient(352.65% 128.18% at 21.33% -5.63%, #FE462D 0%, #FF867D 58.53%, #F52735 100%);"
    >
      <span class="text-white text-lg">我的</span>
    </div>

    <!-- 内容区域 -->
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      head-height="50"
      loading-text="加载中..."
      pulling-text=""
      loosing-text=""
      success-text=""
      :loading-icon="loadingIcon"
      class="custom-loading flex-1 overflow-y-auto"
    >
      <div class="pb-[50px]">
        <div class="h-full w-full bg-windows">
          <div class="overflow-hidden w-full bg-page">
            <div class="page-content-2 pb-safe relative">

              <div class="user-info-container">
                <div class="flex items-center justify-between">
                  <!-- 左侧头像和信息 -->
                  <div class="flex items-center">
                    <img
                      class="w-[44px] h-[44px] rounded-full"
                      @click="jumpToPage('userInfo')"
                      :src="userInfo.head_img?userInfo.head_img: require('@/assets/img/d-avatar.png')"
                      alt=""
                    >
                    <div class="ml-3">
                      <p class="text-[#313131] text-base font-medium mb-1 flex items-center">
                        {{ userInfo.real_name || '未实名' }}
                        <img
                          v-if="userInfo.is_member === 1"
                          src="@/assets/img/profile/SVIP.png"
                          class="w-[36px] h-[16px] ml-1"
                          alt="SVIP"
                        />
                      </p>
                      <p class="phone-number">
                        {{ userInfo.name|maskPhoneNumber }}
                      </p>
                      <div class="invite-code">
                        <span class="label">邀请码：</span>
                        <span class="code">{{ userInfo.code }}</span>
                      </div>
                    </div>
                  </div>

                  <!-- 右侧按钮 -->
                  <div class="flex flex-col items-end">
                    <img
                      src="@/assets/img/profile/btn_sign.png"
                      class="w-[60px] h-[22px]"
                      @click="handleSignIn"
                      alt=""
                    />
                    <div
                      class="flex items-center mt-2 cursor-pointer copy-link"
                      @click="jumpToPage('downloadApp')"
                    >
                      <span>邀请好友</span>
                      <van-icon
                        name="arrow"
                        size="12"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="asset-block">
                <!-- 个人资产 -->
                <div class="total-asset">
                  <div class="flex items-center justify-between">
                    <div class="flex items-center">
                      <span class="text-[18.53px] text-[#333] font-bold font-harmonyos">个人资产</span>
                      <van-icon
                        name="eye-o"
                        class="ml-2"
                        size="16"
                        color="#333"
                      />
                    </div>
                    <div class="action-btns">
                      <button
                        class="action-btn withdraw"
                        @click="jumpToWithdraw(walletInfo.zihuan_wallet)"
                      >提现
                      </button>
                      <button
                        class="action-btn detail"
                        @click="jumpToWithdrawDetail(walletInfo.zihuan_wallet)"
                      >明细
                      </button>
                    </div>
                  </div>
                  <div class="amount-text">{{ walletInfo.zihuan_wallet.amount || '0.00' }}<span class="unit">元</span></div>
                  <div class="quota-text">提现额度：{{ walletInfo.zihuan_wallet.withdraw_quota || '0.00' }}元</div>
                </div>

                <!-- 资产列表 -->
                <div class="resource-grid">
                  <!-- 再生金 -->
                  <div class="resource-item">
                    <div class="flex flex-col">
                      <div class="flex mb-3">
                        <img
                          src="@/assets/img/profile/icon_total.png"
                          class="w-[24px] h-[24px] mr-3 self-center"
                        />
                        <div class="flex flex-col">
                          <div class="amount">{{ walletInfo.reborn_invite_award.amount || '0.00' }}</div>
                          <span class="resource-title">再生金(元)</span>
                        </div>
                      </div>
                      <button
                        class="action-btn withdraw ml-[36px]"
                        @click="jumpToWithdraw(walletInfo.reborn_invite_award)"
                      >提现
                      </button>
                    </div>
                  </div>

                  <!-- 钻石黑卡 -->
                  <div class="resource-item">
                    <div class="flex flex-col">
                      <div class="flex mb-3">
                        <img
                          src="@/assets/img/profile/icon_diamond.png"
                          class="w-[24px] h-[24px] mr-3 self-center"
                        />
                        <div class="flex flex-col">
                          <div class="amount">{{ walletInfo.black_card_wallet.amount || '0.00' }}</div>
                          <span class="resource-title">钻石黑卡(元)</span>
                        </div>
                      </div>
                      <button
                        class="action-btn withdraw ml-[36px]"
                        @click="jumpToWithdraw(walletInfo.black_card_wallet)"
                      >提现
                      </button>
                    </div>
                  </div>

                  <!-- 环保金 -->
                  <div class="resource-item">
                    <div class="flex flex-col">
                      <div class="flex mb-3">
                        <img
                          src="@/assets/img/profile/icon_wallet.png"
                          class="w-[24px] h-[24px] mr-3 self-center"
                        />
                        <div class="flex flex-col">
                          <div class="amount">{{ walletInfo.invite_award.amount || '0.00' }}</div>
                          <span class="resource-title">环保金(元)</span>
                        </div>
                      </div>
                      <button
                        class="action-btn withdraw ml-[36px]"
                        @click="jumpToWithdraw(walletInfo.invite_award)"
                      >
                        提现
                      </button>
                    </div>
                  </div>

                  <!-- 黄金 -->
                  <div class="resource-item">
                    <div class="flex flex-col">
                      <div class="flex mb-3">
                        <img
                          src="@/assets/img/profile/icon_gold.png"
                          class="w-[24px] h-[24px] mr-3 self-center"
                        />
                        <div class="flex flex-col">
                          <div class="amount">{{ walletInfo.gold_wallet.amount || '0.00' }}</div>
                          <span class="resource-title">黄金(克)</span>
                        </div>
                      </div>
                      <button
                        class="action-btn exchange ml-[36px]"
                        @click="handleWithdraw(walletInfo.gold_wallet)"
                      >兑换
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <!--              <div-->
              <!--                  class="env-wallet"-->
              <!--                  @click="jumpToPage('envWallet')"-->
              <!--              >-->
              <!--                <div class="flex items-center justify-center">-->
              <!--                  <img-->
              <!--                      src="@/assets/img/profile/icon_wallet.png"-->
              <!--                      class="wallet-icon"-->
              <!--                  />-->
              <!--                  <span class="wallet-title">资环钱包</span>-->
              <!--                </div>-->
              <!--              </div>-->
              <div
                class="env-wallet"
                @click="jumpToPage('specialSubsidies')"
              >
                <div class="flex items-center justify-center">
                  <img
                    src="@/assets/img/profile/icon_wallet.png"
                    class="wallet-icon"
                  />
                  <span class="wallet-title">困难群众补贴</span>
                </div>
              </div>
              <div
                v-if="userInfo.is_member == 0"
                class="activity-img"
                @click="handleMemberUpgrade"
              >
                <img
                  src="@/assets/img/profile/join_member.png"
                  class="w-full rounded-[10px]"
                />
              </div>
              <div
                v-else
                class="activity-img"
                @click="jumpToPage('applyReturn')"
              >
                <img
                  src="@/assets/img/profile/recovery.png"
                  class="w-full rounded-[10px]"
                />
              </div>
              <div
                class="activity-img"
                @click="jumpToPage('userInvite')"
              >
                <img
                  src="@/assets/img/profile/activity.png"
                  class="w-full rounded-[10px]"
                />
              </div>
              <div class="common-tools">
                <div class="title">常用功能</div>
                <div class="grid grid-cols-3 gap-3">
                  <div
                    class="tool-item"
                    v-for="item in commonTools"
                    :key="item.id"
                    @click="jumpToPage(item.route)"
                  >
                    <img
                      :src="item.icon"
                      class="tool-icon"
                    />
                    <span class="tool-text">{{ item.name }}</span>
                  </div>
                </div>
              </div>
              <div class="other-tools">
                <div class="title-bg">
                  <div class="title">其他功能</div>
                </div>
                <div class="other-tools-list">
                  <div
                    class="tool-item"
                    v-for="item in otherTools"
                    :key="item.id"
                    @click="item.route === 'logout' ? onLoginOut() : jumpToPage(item.route)"
                  >
                    <div class="flex items-center">
                      <img
                        :src="item.icon"
                        class="other-tool-icon"
                      />
                      <span class="other-tool-text">{{ item.name }}</span>
                    </div>
                    <van-icon
                      name="arrow"
                      class="text-[#999999]"
                      size="14"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </van-pull-refresh>
    <PaymentDialog
      v-model:show="showPayment"
      :amount="299"
      @confirm="handlePayConfirm"
    />
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import { WithdrawalType } from "@/config";
import axios from "@/net/axios";
import api from "@/net/api";
import { Dialog, Loading, Overlay, PullRefresh } from "vant";
import PaymentDialog from "@/components/common/PaymentDialog.vue";

export default {
  name: "index",
  components: {
    PaymentDialog,
    [PullRefresh.name]: PullRefresh,
    [Overlay.name]: Overlay,
    [Loading.name]: Loading,
  },
  computed: {
    ...mapState("user", ["userInfo", "userWallet"]),
    ...mapState("systemInfo", ["systemInfo"]),
    displayName() {
      if (this.userInfo.real_name) {
        return this.userInfo.real_name;
      } else if (this.userInfo.name) {
        return this.userInfo.name;
      }
      return "未设置";
    },
  },
  data() {
    return {
      WithdrawalType,
      walletInfo: {
        zihuan_wallet: {
          amount: "0.00",
          title: "个人资产",
          type: "zihuan_wallet",
          withdraw_quota: "0.00",
        },
        reborn_invite_award: {
          amount: "0.00",
          title: "再生金",
          type: "reborn_invite_award",
        },
        invite_award: {
          amount: "0.00",
          title: "环保金",
          type: "invite_award",
          daily_limit: "0.00",
        },
        black_card_wallet: {
          amount: "0.00",
          title: "钻石黑卡",
          type: "black_card_wallet",
        },
        gold_wallet: {
          amount: "0.00",
          title: "黄金",
          type: "gold_wallet",
        },
      },
      refreshing: false,
      loadingIcon: "loading",
      pageLoading: true,
      showPayment: false,
      commonTools: [
        {
          id: 1,
          name: "认证中心",
          icon: require("@/assets/img/profile/icon_auth.png"),
          route: "realNameAuth",
        },
        {
          id: 2,
          name: "客服中心",
          icon: require("@/assets/img/profile/icon_service.png"),
          route: "customService",
        },
        {
          id: 3,
          name: "银行绑定",
          icon: require("@/assets/img/profile/icon_bank.png"),
          route: "bindBankCard",
        },
        // {
        //   id: 4,
        //   name: "投资中心",
        //   icon: require("@/assets/img/profile/icon_invest.png"),
        //   route: "envWallet",
        // },
        {
          id: 5,
          name: "提现记录",
          icon: require("@/assets/img/profile/icon_withdraw.png"),
          route: "withdrawRecord",
        },
        {
          id: 6,
          name: "团队中心",
          icon: require("@/assets/img/profile/icon_team.png"),
          route: "teamCenter",
        },
      ],
      otherTools: [
        {
          id: 1,
          name: "修改密码",
          icon: require("@/assets/img/profile/icon_mdf_pwd.png"),
          route: "modifyPwd",
        },
        {
          id: 2,
          name: "收货地址",
          icon: require("@/assets/img/profile/icon_address.png"),
          route: "address",
        },
        {
          id: 3,
          name: "APP下载",
          icon: require("@/assets/img/profile/icon_download.png"),
          route: "downloadApp",
        },
        {
          id: 4,
          name: "退出登录",
          icon: require("@/assets/img/profile/icon_logout.png"),
          route: "logout",
        },
      ],
    };
  },
  mounted() {
    this.getUserInfo();
    this.getUserWallet();
    this.getSystemInfo();
  },
  filters: {
    maskPhoneNumber(phoneNumber) {
      if (!phoneNumber) return "";
      return phoneNumber.replace(/(\d{3})\d{4}(\d{4})/, "$1****$2");
    },
    formatAmount(value) {
      if (!value) return "0.00";
      const num = parseFloat(value);
      if (isNaN(num)) return "0.00";
      return num.toFixed(2);
    },
  },
  methods: {
    ...mapActions("user", ["getUserInfo", "getIdentifyInfo"]),
    ...mapActions("systemInfo", ["getSystemInfo"]),
    jumpToWithdraw(data) {
      if (data.type === "black_card_wallet") {
        this.$router.push("/blackGoldCard");
        return;
      }
      // Check if it's environmental points withdrawal
      // if (data.type === 'invite_award') {
      //   this.$dialog.alert({
      //     title: '提示',
      //     message: '请完成资环钱包提现',
      //   });
      //   return;
      // }
      // Navigate to investment center for renewable resource investment points
      // if (data.type === 'reborn_invite_award') {
      //   this.$router.push({name: 'investCentral'});
      //   return;
      // }
      // Check membership for recycled gold and eco gold withdrawals
      if (data.type === "invite_award" || data.type === "reborn_invite_award") {
        if (!this.userInfo.is_member) {
          this.$dialog.alert({
            title: "提示",
            message: "需要成为会员才能提现",
          });
          return;
        }
      }
      this.$router.push({
        name: "withdrawal",
        query: data,
      });
    },
    jumpToWithdrawDetail(data) {
      this.$router.push({
        name: "fundDetail",
        query: {
          type: data.type,
        },
      });
    },
    jumpToPage(name) {
      if (name == "downloadApp") {
        this.$router.push({
          name: "inviteUser",
          query: { code: this.userInfo.code },
        });
        return;
      }
      if (name == "customService") {
        window.location.href = this.systemInfo.customerService;
        return;
      }
      if (name == "teamReward") {
        this.$router.push({
          name: "withdrawRecord",
          query: { type: "income_wallet" },
        });
        return;
      }
      this.$router.push({ name });
    },
    getUserWallet() {
      axios
        .get(api.userWallet)
        .then((res) => {
          const coinList = res.data.coinList;
          const walletData = {
            zihuan_wallet: {
              amount: "0.00",
              title: "个人资产",
              type: "zihuan_wallet",
              withdraw_quota: "0.00",
            },
            reborn_invite_award: {
              amount: "0.00",
              title: "再生金",
              type: "reborn_invite_award",
            },
            invite_award: {
              amount: "0.00",
              title: "环保金",
              type: "invite_award",
            },
            black_card_wallet: {
              amount: "0.00",
              title: "钻石黑卡",
              type: "black_card_wallet",
            },
            gold_wallet: {
              amount: "0.00",
              title: "黄金",
              type: "gold_wallet",
            },
          };

          // 更新数据
          coinList.forEach((coin) => {
            if (walletData[coin.name_en]) {
              walletData[coin.name_en] = {
                amount: coin.num || "0.00",
                title: coin.name,
                type: coin.name_en,
                // 如果是个人资产，添加提现额度
                ...(coin.name_en === "zihuan_wallet"
                  ? { withdraw_quota: coin.withdraw_quota || "0.00" }
                  : {}),
              };
            }
          });

          this.walletInfo = walletData;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
    onLoginOut() {
      this.$dialog
        .confirm({
          title: "提示",
          message: "确定要退出登录吗？",
        })
        .then(() => {
          this.$store.commit("tabs/ACCOUNT_LOGOUT_FAILURE", true);
        })
        .catch(() => {});
    },
    loadMore() {
      axios
        .get(api.receiveList)
        .then((res) => {
          if (res.data && res.data.length > 0) {
            this.recommendRecords = res.data.map((item) => {
              return item.recom_num;
            });
          }
          this.loading = false;
          this.refreshing = false;
          this.finished = true;
        })
        .catch((err) => {
          this.loading = false;
          this.refreshing = false;
          err.msg && this.$toast(err.msg);
        });
    },
    onCopyText() {
      console.log("复制邀请链接", this.userInfo);
      const text = `${this.$config.WEB_URL}/register?code=${this.userInfo.code}`;
      const input = document.createElement("input");
      input.value = text;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length),
        document.execCommand("Copy");
      document.body.removeChild(input);
      this.$toast("复制成功！");
    },
    onRefresh() {
      this.refreshing = true;
      this.pageLoading = true;
      Promise.all([
        this.getUserInfo(),
        this.getSystemInfo(),
        this.getUserWallet(),
      ]).finally(() => {
        this.refreshing = false;
        this.pageLoading = false;
      });
    },
    handleSignIn() {
      axios
        .post(api.signIn)
        .then((res) => {
          this.$toast("签到成功");
          this.getUserInfo();
          this.getUserWallet();
        })
        .catch((err) => {
          if (err.code === 430) {
            this.$dialog
              .alert({
                message: err.msg || "请先完成实名认证",
                confirmButtonText: "去认证",
                confirmButtonColor: "#F34947",
              })
              .then(() => {
                this.$router.push({ name: "realNameAuth" });
              });
          } else {
            err.msg && this.$toast(err.msg);
          }
        });
    },
    async handlePayConfirm({ payType }) {
      try {
        const params = {
          source: 6,
          pay_type: payType,
          amount: this.activationAmount,
        };

        const res = await axios.post(api.balancePay, params);
        this.showPayment = false;

        // 余额支付
        if (payType === 8) {
          await Dialog.alert({
            title: "提示",
            message: "升级成功！",
            theme: "round-button",
            confirmButtonText: "确定",
            confirmButtonColor: "#F52735",
          });
          return;
        }

        // 其他支付方式
        if (res.data?.payUrl) {
          window.location.href = res.data.payUrl;
        }
      } catch (err) {
        this.$toast(err.msg || "支付失败");
      }
    },
    handleMemberUpgrade() {
      this.showPayment = true;
    },
    async handleWithdraw(data) {
      // Check for gold wallet exchange
      if (data.type === "gold_wallet") {
        // Check if user has invited 3 friends who have activated
        if (this.userInfo.recommend_identify_num < 3) {
          this.$dialog.alert({
            title: "提示",
            message: "需要邀请三位好友激活后才能兑换",
          });
          return;
        }

        // Check if gold amount is at least 10g
        if (data.amount < 10) {
          this.$dialog.alert({
            title: "提示",
            message: "需要满10g才能兑换",
          });
          return;
        }

        // Show 2025 exchange message if conditions are met
        this.$dialog.alert({
          title: "提示",
          message: "2025年1月1日全额兑换",
        });
        return;
      }

      if (!data || !data.amount) {
        this.$dialog.alert({
          title: "提示",
          message: "暂无可提现金额",
        });
        return;
      }

      const loading = this.$toast.loading({
        message: "提交中...",
        forbidClick: true,
      });

      try {
        const params = {
          num: data.amount,
          coin_type: data.type,
        };
        await axios.post(api.userWithdrawal, params);
        this.$dialog
          .alert({
            title: "提示",
            message: "提现申请成功",
          })
          .then(() => {
            this.getUserWallet();
          });
      } catch (error) {
        this.$dialog.alert({
          title: "提示",
          message: error.msg || "提现失败，请稍后重试",
        });
      } finally {
        loading.clear();
      }
    },
  },
};
</script>

<style scoped lang="less">
.van-nav-bar {
  background-color: #b41212;
}

.van-nav-bar /deep/ .van-nav-bar__title {
  color: #fff;
}

.page-content /deep/ .van-grid-item__text {
  font-size: 14px;
}

.item-btn {
  width: 100%;
  background-color: #ffffff;
  border-radius: 8px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #333435;
  margin: 20px auto auto;
}

.copy-link-btn {
  background: #ffe6c8;
  border-radius: 5px;
  font-family: MiSans;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  text-align: center;
  color: #f14b47;
  padding: 5px 10px;
}

.bg-info {
  padding: 0 10px;
}

.kf-container {
  width: 61px;
  height: 78px;
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  right: 10px;
}

.user-info-container {
  margin: 12px 16px;
  padding: 16px;
}

.avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.name {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.info-text {
  font-size: 14px;
  color: #666;
}

.copy-btn {
  background: #ffe6c8;
  border-radius: 15px;
  padding: 5px 12px;
  font-size: 14px;
  color: #f14b47;
}

.sign-btn {
  height: 26px;
}

.invite-img {
  width: calc(100% - 32px);
  margin: 16px;
  border-radius: 10px;
}

// 加下拉刷新样式
.custom-loading {
  -webkit-overflow-scrolling: touch;

  :deep(.van-pull-refresh__head) {
    .van-loading {
      .van-loading__spinner {
        color: #fff !important;
      }

      .van-loading__text {
        color: #fff !important;
      }
    }

    .van-pull-refresh__text {
      color: #fff !important;
    }
  }
}

.asset-block {
  background: #fff;
  border-radius: 17.361px;
  box-shadow: 3.472px 6.944px 13.889px 3.472px #fff2f2;
  margin: 12px 16px;
  overflow: hidden;

  .total-asset {
    padding: 16px;
    border-bottom: 1px solid #f5f5f5;

    .amount-text {
      color: #d1645a;
      font-family: "PingFang SC";
      font-size: 15.89px;
      font-weight: 400;
      line-height: normal;
      margin-top: 12px;

      .unit {
        font-size: 14px;
        margin-left: 4px;
      }
    }

    .quota-text {
      color: #999;
      font-size: 14px;
      margin-top: 8px;
    }
  }

  .action-btns {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  }

  .action-btn {
    min-width: 51px;
    height: 21px;
    border-radius: 4px;
    font-size: 12px;

    &.withdraw {
      background: #fa4949;
      color: #ffffff;
    }

    &.detail {
      background: #ba8e8e;
      color: #ffffff;
    }

    &.exchange {
      background: #ff9f1c;
      color: #ffffff;
    }
  }

  .resource-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
    padding: 16px;
  }

  .resource-item {
    background: #f7f7f7;
    border-radius: 8px;
    padding: 16px;

    .amount {
      color: #333;
      font-family: "HarmonyOS Sans SC";
      font-size: 18.53px;
      font-weight: 700;
      line-height: 1;
    }

    .resource-title {
      color: #666666;
      font-size: 12px;
      margin-top: 4px;
      line-height: 1;
    }

    .action-btn {
      width: 80px;
      height: 32px;
      border-radius: 4px;
      font-size: 14px;

      &.withdraw {
        background: #fa4949;
        color: #ffffff;
      }

      &.exchange {
        background: #ff9f1c;
        color: #ffffff;
      }
    }
  }
}

.fund-title {
  color: #b59f9d;
  font-family: "PingFang SC";
  font-size: 13.889px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-edge: cap;
  leading-trim: both;
}

.fund-amount {
  color: #333;
  font-family: "Yuanti SC";
  font-size: 21.528px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-edge: cap;
  leading-trim: both;
}

.copy-link {
  span {
    color: #563634;
    font-family: "PingFang SC";
    font-size: 11.111px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .van-icon {
    margin-left: 4px;
    color: #563634;
  }
}

.invite-code {
  .label {
    color: #b59f9d;
    font-family: "PingFang SC";
    font-size: 12.5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-edge: cap;
    leading-trim: both;
  }

  .code {
    color: #ff4242;
    font-family: "PingFang SC";
    font-size: 12.5px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-edge: cap;
    leading-trim: both;
  }
}

.phone-number {
  color: #333;
  font-family: "Yuanti SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.common-tools {
  margin: 0 16px 12px;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;

  .title {
    font-family: "PingFang SC";
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    margin-bottom: 16px;
  }

  .tool-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;

    .tool-icon {
      width: 52px;
      height: 52px;
      flex-shrink: 0;
    }

    .tool-text {
      color: #999;
      text-align: center;
      font-family: "HarmonyOS Sans";
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.other-tools {
  background: #ffffff;

  .title-bg {
    background: #ecf1f9;
    padding: 10px;

    .title {
      font-family: "PingFang SC";
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
  }

  .other-tools-list {
    padding: 0 16px;

    .tool-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      cursor: pointer;
      border-bottom: 1px solid #f5f5f5;

      &:last-child {
        border-bottom: none;
      }

      .other-tool-icon {
        width: 14px;
        height: 14px;
        flex-shrink: 0;
      }

      .other-tool-text {
        color: #2a3563;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        letter-spacing: -0.24px;
        margin-left: 12px;
      }
    }
  }
}

.env-wallet {
  margin: 12px 16px 0;
  padding: 16px;
  background: #ffffff;
  border-radius: 8px;

  .wallet-icon {
    width: 20px;
    height: 20px;
  }

  .wallet-title {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    margin-left: 8px;
  }
}
</style>
