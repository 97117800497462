<template>
  <div class="flex flex-col h-screen">
    <!-- loading遮罩 -->
    <van-overlay
      :show="pageLoading"
      class="flex items-center justify-center"
    >
      <van-loading
        type="spinner"
        color="#fff"
        size="36"
        vertical
      >加载中...</van-loading>
    </van-overlay>

    <!-- 标题栏 -->
    <div
      class="h-[44px] flex items-center px-4 relative"
      style="background: radial-gradient(352.65% 128.18% at 21.33% -5.63%, #FE462D 0%, #FF867D 58.53%, #F52735 100%);"
    >
      <van-icon
        name="arrow-left"
        class="text-white"
        @click="onClickLeft"
      />
      <span class="text-white text-lg absolute left-1/2 -translate-x-1/2">资环钻石黑卡</span>
    </div>

    <!-- 内容区域 -->
    <div
      class="flex-1 overflow-y-auto pb-16"
      style="background: linear-gradient(180deg, #FF4D4F 0%, #FFE0E0 100%);"
    >
      <div class="p-4">
        <!-- Bank card container -->
        <div class="bg-white rounded-lg p-4">
          <!-- Bank card image -->
          <bank-card-generator
            v-if="bankCardInfo"
            :card-data="bankCardInfo"
          />
          <img
            v-else
            src="@/assets/img/bank-logo.png"
            class="w-full"
          />

          <div class="text-center text-base my-4">免费领取银行卡</div>

          <!-- Form fields -->
          <div class="space-y-4">
            <van-field
              v-model="formData.name"
              label="姓名："
              placeholder="输入姓名"
              class="bg-gray-50 rounded"
            />

            <van-field
              v-model="formData.phone"
              label="手机号："
              placeholder="输入手机号"
              class="bg-gray-50 rounded"
            />

            <van-field
              v-model="formData.address"
              label="收货地址："
              placeholder="输入地址"
              class="bg-gray-50 rounded"
            />
          </div>

          <!-- Submit button -->
          <van-button
            block
            class="mt-6"
            :color="buttonStyle.color"
            :disabled="buttonStyle.disabled"
            @click="handleBind"
          >{{ buttonStyle.text }}</van-button>

          <!-- Mailing information -->
          <div class="mt-8">
            <div class="text-center text-base mb-4">邮寄信息</div>
            <div class="space-y-4">
              <div class="flex justify-between items-center">
                <span>邮寄状态</span>
                <span class="text-[#FF4D4F]">{{ mailStatus }}</span>
              </div>
              <div class="flex justify-between items-center">
                <span>领取状况</span>
                <span class="text-[#FF4D4F]">{{ receiveStatus }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Field, Button, Loading, Overlay, Dialog } from "vant";
import BankCardGenerator from "@/components/bankCardGenerator.vue";
import axios from "@/net/axios";
import api from "@/net/api";

export default {
  name: "GetBlackGoldCards",
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
    BankCardGenerator,
  },
  data() {
    return {
      pageLoading: false,
      bankCardInfo: null,
      formData: {
        name: "",
        phone: "",
        address: "",
      },
    };
  },
  computed: {
    buttonStyle() {
      if (this.bankCardInfo?.get_address) {
        return {
          text: "已领取",
          color: "#999999",
          disabled: true,
        };
      }
      return {
        text: "领取",
        color: "#FF4D4F",
        disabled: false,
      };
    },

    mailStatus() {
      return this.bankCardInfo?.express_time ? "邮寄中" : "待邮寄";
    },

    receiveStatus() {
      return this.bankCardInfo?.get_address ? "已领取" : "未领取";
    },
  },
  mounted() {
    this.getBankCardInfo();
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    async handleBind() {
      // Form validation
      if (!this.formData.name.trim()) {
        await Dialog.alert({
          title: "提示",
          message: "请输入姓名",
          theme: "round-button",
          confirmButtonColor: "#F52735",
        });
        return;
      }

      if (!this.formData.phone.trim()) {
        await Dialog.alert({
          title: "提示",
          message: "请输入手机号",
          theme: "round-button",
          confirmButtonColor: "#F52735",
        });
        return;
      }

      // Phone number validation using regex
      const phoneRegex = /^1[3-9]\d{9}$/;
      if (!phoneRegex.test(this.formData.phone)) {
        await Dialog.alert({
          title: "提示",
          message: "请输入正确的手机号",
          theme: "round-button",
          confirmButtonColor: "#F52735",
        });
        return;
      }

      if (!this.formData.address.trim()) {
        await Dialog.alert({
          title: "提示",
          message: "请输入收货地址",
          theme: "round-button",
          confirmButtonColor: "#F52735",
        });
        return;
      }

      try {
        this.pageLoading = true;

        // 创建 FormData 对象
        const formData = new FormData();
        formData.append("get_name", this.formData.name);
        formData.append("get_mobile", this.formData.phone);
        formData.append("get_address", this.formData.address);
        formData.append("id", this.bankCardInfo.id);

        await axios.post(api.editBankOrder, formData);

        await Dialog.alert({
          title: "提示",
          message: "提交成功，请耐心等待收件。",
          theme: "round-button",
          confirmButtonText: "确定",
          confirmButtonColor: "#F52735",
        });

        // 刷新卡片信息
        this.getBankCardInfo();
      } catch (error) {
        await Dialog.alert({
          title: "提示",
          message: error.msg || "提交失败，请稍后重试",
          theme: "round-button",
          confirmButtonColor: "#F52735",
        });
      } finally {
        this.pageLoading = false;
      }
    },
    getBankCardInfo() {
      this.pageLoading = true;
      axios
        .get(api.myBankOrder, { params: { type: 1 } })
        .then((res) => {
          this.bankCardInfo = res.data;
          if (res.data?.get_address) {
            this.formData = {
              name: res.data.get_name,
              phone: res.data.get_mobile,
              address: res.data.get_address,
            };
          }
        })
        .catch((err) => {
          console.log("获取银行卡信息失败", err);
        })
        .finally(() => {
          this.pageLoading = false;
        });
    },
  },
};
</script>

<style scoped lang="less">
</style>
