<template>
  <div class="h-full w-full flex flex-col">
    <!-- 标题栏 -->
    <nav-bar
      :title="title"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      :safe-area-inset-top="true"
      :border="false"
      class="fixed top-0 left-0 right-0 z-20"
    />

    <!-- 标题栏占位 -->
    <div class="h-[44px]"></div>

    <!-- Tab栏固定 -->
    <div class="sticky top-[44px] z-10 bg-white">
      <van-tabs v-model="activeTab" @click="onClick" class="custom-tabs">
        <van-tab title="个人资产" name="zihuan_wallet" />
        <van-tab title="再生金" name="reborn_invite_award" />
        <van-tab title="环保金" name="invite_award" />
        <van-tab title="黑卡" name="black_card_wallet" />
<!--        <van-tab title="团队奖励" name="team_award" />-->
<!--        <van-tab title="股权" name="stock" />-->
      </van-tabs>
    </div>

    <!-- 列表内容区域 -->
    <div class="flex-1 overflow-auto pt-3">
      <PullRefresh v-model="refreshing" @refresh="refreshData" class="min-h-full">
        <List
          v-model="loading"
          :finished="finished"
          :finished-text="list.length > 0 ? '没有更多了' : ''"
          @load="loadMore"
          class="h-full"
          :loading-text="pageNo > 1 ? '加载中...' : ''"
        >
          <!-- 首次加载中 -->
          <div v-if="firstLoading" class="py-8 text-center">
            <van-loading type="spinner" color="#FF5848" />
          </div>

          <!-- 无数据显示空视图 -->
          <van-empty
            v-else-if="list.length === 0 && !loading && !firstLoading"
            class="custom-empty"
            image="search"
            description="暂无相关数据"
          />

          <!-- 列表内容 -->
          <template v-else>
            <div v-for="(item, index) in list" :key="index" class="bg-white rounded-lg p-4 mb-3 mx-4">
              <div class="flex justify-between items-center mb-2">
                <span class="text-lg font-bold">
                  {{ '提现到'+item?.bank_name }}
                </span>
                <span :class="getStatusColorClass(item.status)">
                  {{ activeTab === 'reborn_invite_award' ? '提现成功' : getReviewStatusLabel(item.status) }}
                </span>
              </div>
              <div class="flex items-center mb-2">
                <span class="text-red-500 text-lg font-bold mr-1">金额：</span>
                <span class="text-red-500 text-lg font-bold">¥ {{ item.num }}</span>
              </div>
              <div class="text-gray-500">
                {{ item.createtime | timeFormat('YYYY/MM/DD HH:mm') }}
              </div>
              <div class="text-red-500 mt-2" v-if="item.remark">
                {{item.remark}}
              </div>
            </div>
          </template>
        </List>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import axios from "@/net/axios";
import api from "@/net/api";
import { Empty, List, NavBar, PullRefresh, Tab, Tabs } from "vant";
import { getReviewStatusLabel, ReviewStatus, getNTypeLabel, NType } from "@/config";

export default {
  components: { NavBar, List, Empty, PullRefresh, [Tab.name]: Tab, [Tabs.name]: Tabs },
  data() {
    return {
      getReviewStatusLabel,
      getNTypeLabel,
      NType,
      ReviewStatus,
      title: this.$route.meta.title,
      count: 0,
      pageSize: 10,
      pageNo: 1,
      list: [],
      loading: false,
      finished: false,
      refreshing: false,
      firstLoading: false,
      activeTab: this.$route.query.type || 'zihuan_wallet',
      isRequesting: false, // 添加请求状态标志
    };
  },
  created() {
    this.loadMore(); // 组件创建后加载数据
  },
  methods: {
    onClick(name) {
      if (this.isRequesting) return; // 防止请求中切换
      this.activeTab = name;
      this.list = [];
      this.pageNo = 1;
      this.finished = false;
      this.loading = true;
      this.firstLoading = false;

      // 使用 nextTick 确保状态更新后再调用加载
      this.$nextTick(() => {
        this.loadMore();
      });
    },
    resetList() {
      if (this.isRequesting) return;
      this.list = [];
      this.pageNo = 1;
      this.finished = false;
      this.loading = true;
      this.firstLoading = false;
      this.loadMore();
    },
    refreshData() {
      if (this.isRequesting) return;
      this.resetList();
      this.refreshing = false;
    },
    async loadMore() {
      if (this.isRequesting) return;

      if(this.refreshing) {
        this.list = [];
        this.pageNo = 1;
      }

      this.isRequesting = true;

      try {
        const res = await axios.get(api.withdrawalRecordList, {
          params: {
            page: this.pageNo,
            coin_type: this.activeTab,
          }
        });

        if (this.pageNo === 1) {
          this.list = res.data.list;
          this.count = res.data.total;
        } else {
          this.list = [...this.list, ...res.data.list];
        }

        if (this.list.length < this.count) {
          this.pageNo++;
          this.finished = false;
        } else {
          this.finished = true;
        }
      } catch (err) {
        this.finished = true;
        err.msg && this.$toast(err.msg);
      } finally {
        this.loading = false;
        this.firstLoading = false;
        this.isRequesting = false;
      }
    },
    onClickLeft() {
      this.$router.go(-1);
    },
    getStatusColorClass(status) {
      if (this.activeTab === 'reborn_invite_award') {
        return 'text-green-500';
      }

      switch (status) {
        case ReviewStatus.PAID:
          return 'text-green-500';
        case ReviewStatus.PENDING:
          return 'text-blue-500';
        case ReviewStatus.PAYMENT_FAILED:
          return 'text-red-500';
        default:
          return 'text-gray-500';
      }
    },
  },
};
</script>

<style scoped lang="less">
// 自定义 tab 样式
.custom-tabs {
  :deep(.van-tabs__wrap) {
    background: white;
  }

  :deep(.van-tab) {
    color: #313131;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  :deep(.van-tab--active) {
    color: #FF5848;
    font-weight: 500;
  }

  :deep(.van-tabs__line) {
    width: 20px !important;
    height: 3px;
    background: #FF5848;
    border-radius: 1.5px;
  }

  :deep(.van-tabs__nav) {
    border: none;
  }
}

// 自定义空视图样式
.custom-empty {
  padding: 60px 0;

  :deep(.van-empty__image) {
    width: 120px;
    height: 120px;
  }

  :deep(.van-empty__description) {
    color: #999;
    font-size: 14px;
    line-height: 20px;
    margin-top: 20px;
  }
}

// 添加列表相关样式
:deep(.van-list) {
  min-height: 100%;
}

:deep(.van-pull-refresh) {
  min-height: 100%;
}

// 确保内容区域可以滚动
.overflow-auto {
  -webkit-overflow-scrolling: touch;
}
</style>
