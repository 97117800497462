

export default {
  login: "/api/auth/login", //登录
  register: "/api/auth/register", //注册
  navList: "/api/navigation/list", //菜单
  getBanner: "/api/index/banner", //获取banner
  signIn: "/api/user/sign", //用户签到
  systemInfo: "/api/index/configInfo", //获取系统信息
  userInfo: "/api/user/userInfo", //获取用户信息
  realAuth: "/api/user/identify", //实名认证
  identifyInfo:"/api/user/identifyLog",//实名认证信息获取
  bindBankCard: "/api/user/addBank", //添加或修改银行卡
  bankCardInfo:"/api/user/myBank",//获取银行卡信息
  teamList: "/api/user/teamList", //团队列表
  uploadAvatar: "/api/user/updateHeadImg", //上传头像
  uploadImage: "/api/basic/upload", //上传图片
  balanceLog: "/api/assets/moneyRecord", //资金明细
  newsList: "/api/index/notice", //新闻列表
  newsDetails: "/api/index/noticeDetail", //新闻详情
  userWallet: "/api/assets/myAssets", //我的资产
  drawNum: "/api/turntable/prizes", //剩余抽奖次数
  luckNum: "/api/turntable/join", //抽奖
  drawRecord: "/api/turntable/joinLog", //抽奖记录
  userWithdrawal: "/api/assets/withdraw", //用户提现
  withdrawalRecordList: "/api/assets/withdrawLog", //提现记录
  myHouse: "/api/user/my-house", //我的房产
  receiveList: "/api/user/myInviteAward", //我的邀请奖励数据
  levelList: "/api/user/levelList", //工会数据
  receiveAward: "/api/user/getInviteAward", //领取奖励
  receiveNote: "/api/user/receive-note", //领取本票
  cardAddressList: "/api/user/card-address-list ", //卡包
  welfareList: "/api/ido/idoList", //项目列表
  balancePay: "/api/pay/pay", //支付
  rechargePay: "/api/ido/joinIdo", //余额支付
  buyBonusList: "/api/ido/myInvestList", //项目列表
  reviceReg: "/api/user/receive-reg", //注册领取奖励
  addressList: "/api/user/myAddress ", //地址列表
  addressDetail: "/api/user/myAddress", //地址详情
  addAddress: "/api/user/addAddress", //添加地址
  editAddress: "/api/user/addAddress", //编辑地址
  deleteAddress: "/api/address/del", //删除地址
  modifyPwd: "/api/auth/passwordByIdCardNo", //修改密码
  siteCode: "/api/auth/captchaImg", //验证码
  addOrder: "/api/pay/add-order", //生成订单
  uploadPaymentImg: "/api/pay/payment", //上传支付凭证
  awardList: "/api/turntable/awardList", //中奖记录
  getAward: "/api/turntable/getAward", //领取中奖奖励
  myAddress: "/api/user/myAddress", //我的地址
  addGoldAddress: "/api/user/addAddress", //添加/修改地址
  activeInvite: "/api/user/activeInvite", //邀请激活
  inviteProvinceUserList: "/api/user/inviteProvinceUserList", //邀请省份明细
  myInvestment: "/api/assets/myInvestment", //投资中心
  zihuanInviteList: "/api/user/zihuanInviteList", //资环钱包激活类型
  getSubsidyRecord: "/api/user/getSubsidyRecord", //补贴登记
  updateSubsidyRecord: "/api/user/updateSubsidyRecord", //补贴登记更新
  activePartnerInvite: "/api/user/activePartnerInvite", //合伙人申请
  partnerInviteList: "/api/user/partnerInviteList", //合伙人领取列表
  getPartnerInviteAward: "/api/user/getPartnerInviteAward", //合伙人领取奖励
  myBankOrder: "/api/user/myBankOrder", //我的黑金卡信息
  apply_coin_restore: "/api/user/apply_coin_restore", //申请恢复
  apply_coin_return: "/api/user/apply_coin_return", //立即返还
  editBankOrder: "/api/user/editBankOrder", //编辑黑金卡信息-点击领取调用
};
