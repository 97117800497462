<template>
  <div class="flex flex-col h-screen">
    <!-- loading遮罩 -->
    <van-overlay
        :show="pageLoading"
        class="flex items-center justify-center"
    >
      <van-loading
          type="spinner"
          color="#fff"
          size="36"
          vertical
      >加载中...
      </van-loading>
    </van-overlay>

    <!-- 标题栏 -->

    <div class="h-[44px] flex items-center px-4 relative" style="background: radial-gradient(352.65% 128.18% at 21.33% -5.63%, #FE462D 0%, #FF867D 58.53%, #F52735 100%);">
      <van-icon name="arrow-left" class="text-white" @click="onClickLeft" />
      <span class="text-white text-lg absolute left-1/2 -translate-x-1/2">资环钻石黑卡</span>
    </div>

    <!-- 内容区域 -->
    <div
        class="flex-1 overflow-y-auto pb-16"
        style="background: linear-gradient(180deg, #FF4D4F 0%, #FFE0E0 100%);"
    >
      <div class="p-4">
        <!-- Bank card container -->
        <div class="bg-white rounded-lg p-4">
          <!-- Bank card image -->
          <bank-card-generator
              v-if="bankCardInfo"
              :card-data="bankCardInfo"
          />
          <img
              v-else
              src="@/assets/img/bank-logo.png"
              class="w-full"
          />
          <!-- Wallet Balance -->
          <div class="mt-4 text-right text-sm text-gray-600">
            可用余额：{{ walletBalance }}
          </div>
          <!-- Input field -->
          <div class="mt-4">
            <van-field
                v-model="amount"
                type="digit"
                placeholder="请输入提现金额"
                class="bg-[#F5F5F5] rounded"
            />
          </div>

          <!-- Action buttons -->
          <div class="flex gap-4 mt-4">
            <van-button
                block
                class="flex-1"
                color="#FF4D4F"
                @click="handleWithdraw"
            >提现
            </van-button>
            <van-button
                block
                class="flex-1"
                color="#FFB6B9"
                @click="showDetails"
            >明细
            </van-button>
          </div>

          <!-- Activation button -->
          <van-button
              block
              class="mt-4"
              :color="activationButtonStyle.color"
              :disabled="activationButtonStyle.disabled"
              @click="handleActivate"
          >{{ activationButtonStyle.text }}
          </van-button>

          <!-- Payment methods -->
          <div class="mt-6">
            <div class="flex justify-around">
              <div class="flex flex-col items-center">
                <img
                    src="@/assets/img/wechat-pay.png"
                    class="w-12 h-12 mb-2"
                />
                <van-radio
                    name="wechat"
                    v-model="paymentMethod"
                />
              </div>
              <div class="flex flex-col items-center">
                <img
                    src="@/assets/img/alipay.png"
                    class="w-12 h-12 mb-2"
                />
                <van-radio
                    name="alipay"
                    v-model="paymentMethod"
                />
              </div>
              <div class="flex flex-col items-center">
                <img
                    src="@/assets/img/union-pay.png"
                    class="w-12 h-12 mb-2"
                />
                <van-radio
                    name="unionpay"
                    v-model="paymentMethod"
                />
              </div>
            </div>
          </div>

          <!-- Bind button -->
          <van-button
              block
              class="mt-6"
              color="#FF4D4F"
              @click="handleBind"
          >前往绑定
          </van-button>
        </div>
      </div>
    </div>
    <PaymentDialog
        v-model:show="showPayment"
        :amount="activationAmount"
        @confirm="handlePayConfirm"
    />
  </div>
</template>

<script>
import {Field, Button, Radio, Loading, Overlay, Dialog} from "vant";
import BankCardGenerator from "@/components/bankCardGenerator.vue";
import PaymentDialog from "@/components/common/PaymentDialog.vue";
import axios from "@/net/axios";
import api from "@/net/api";
import {mapActions, mapState} from "vuex";

export default {
  name: "blackGoldCard",
  components: {
    [Field.name]: Field,
    [Button.name]: Button,
    [Radio.name]: Radio,
    [Loading.name]: Loading,
    [Overlay.name]: Overlay,
    BankCardGenerator,
    PaymentDialog,
  },
  data() {
    return {
      pageLoading: false,
      amount: "",
      bankCardInfo: null,
      walletBalance: "0",
      paymentMethod: "wechat",
      showPayment: false,
    };
  },
  computed: {
    ...mapState("user", ["userInfo", "userWallet"]),

    activationAmount() {
      return 199;
    },
    activationButtonStyle() {
      if (!this.bankCardInfo) {
        return {
          text: "激活",
          color: "#FF4D4F",
          disabled: false,
        };
      }

      switch (this.bankCardInfo.status) {
        case 2:
          return {
            text: "已激活",
            color: "#999999",
            disabled: true,
          };
        case 1:
        default:
          return {
            text: "激活",
            color: "#FF4D4F",
            disabled: false,
          };
      }
    },
  },
  mounted() {
    this.getUserInfo();
    this.getBankCardInfo();
    this.getUserWallet();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    onClickLeft() {
      this.$router.go(-1);
    },
    getBankCardInfo() {
      this.pageLoading = true;
      axios
          .get(api.myBankOrder, {params: {type: 1}})
          .then((res) => {
            this.bankCardInfo = res.data;
          })
          .catch((err) => {
            console.log("获取银行卡信息失败", err);
          })
          .finally(() => {
            this.pageLoading = false;
          });
    },
    getUserWallet() {
      this.pageLoading = true;
      axios
          .get(api.userWallet)
          .then((res) => {
            const walletInfo = res.data.coinList.find(
                (coin) => coin.name_en === "black_card_wallet"
            );
            this.walletBalance = walletInfo ? walletInfo.num : "0";
          })
          .catch((err) => {
            console.log("获取钱包信息失败", err);
            err.msg && this.$toast(err.msg);
          })
          .finally(() => {
            this.pageLoading = false;
          });
    },
    handleWithdraw() {
      console.log("提现",!this.userInfo.is_member)
      if (!this.userInfo.is_member) {
        this.$dialog.alert({
          title: "提示",
          message: "需要成为会员才能提现",
        });
        return;
      }
      // 验证输入值是否为空
      if (!this.amount) {
        this.$dialog.alert({
          message: "请输入提现金额",
          confirmButtonText: "确定",
          confirmButtonColor: "#F34947",
        });
        return;
      }

      // 验证输入值是否大于0
      const withdrawAmount = parseFloat(this.amount);
      if (withdrawAmount <= 0) {
        this.$dialog.alert({
          message: "提现金额必须大于0",
          confirmButtonText: "确定",
          confirmButtonColor: "#F34947",
        });
        return;
      }

      // 验证余额是否充足
      if (withdrawAmount > parseFloat(this.walletBalance)) {
        this.$dialog.alert({
          message: "余额不足",
          confirmButtonText: "确定",
          confirmButtonColor: "#F34947",
        });
        return;
      }

      // this.$dialog.alert({
      //   message: "请收到实体卡激活或者激活电子卡片",
      //   confirmButtonText: "确定",
      //   confirmButtonColor: "#F34947",
      // });
      // return;


      // 调用提现接口
      this.pageLoading = true;
      axios
          .post(api.userWithdrawal, {
            num: this.amount,
            coin_type: "black_card_wallet",
          })
          .then(() => {
            this.$dialog
                .alert({
                  message: "提现审核到账10个工作日内完成到账",
                  confirmButtonText: "确定",
                  confirmButtonColor: "#F34947",
                })
                .then(() => {
                  this.amount = "";
                  this.getUserWallet();
                });
          })
          .catch((err) => {
            err.msg &&
            this.$dialog.alert({
              message: err.msg,
              confirmButtonText: "确定",
              confirmButtonColor: "#F34947",
            });
          })
          .finally(() => {
            this.pageLoading = false;
          });
    },
    showDetails() {
      this.$router.push({
        name: "fundDetail",
        query: {
          type: "black_card_wallet",
        },
      });
    },
    handleActivate() {
      if (!this.userInfo.is_member) {
        this.$dialog.alert({
          title: "提示",
          message: "需要成为会员才能激活",
        });
        return;
      }
      this.$dialog.alert({
        message: "激活电子卡后后续收到实体卡无需实名，完成激活即可",
        confirmButtonText: "确定",
        confirmButtonColor: "#F34947",
      }).then(() => {
        this.showPayment = true;
      })
          .catch(() => {
          });

    },
    async handleBind() {
      if (!this.userInfo.is_member) {
        this.$dialog.alert({
          title: "提示",
          message: "需要成为会员才能绑定",
        });
        return;
      }
      if(this.bankCardInfo.status==1){
        this.$dialog.alert({
          title: "提示",
          message: "请先激活钻石黑卡！",
        });
        return;
      }
      await Dialog.alert({
        title: "提示",
        message: "暂未开放",
        theme: "round-button",
        confirmButtonText: "确定",
        confirmButtonColor: "#F52735",
      });
    },
    async handlePayConfirm({payType}) {
      try {
        const params = {
          source: 4,
          pay_type: payType,
          amount: this.activationAmount,
          project_id: 1,
        };

        const res = await axios.post(api.balancePay, params);
        this.showPayment = false;

        // 余额支付
        if (payType === 8) {
          await Dialog.alert({
            title: "提示",
            message: "激活成功！",
            theme: "round-button",
            confirmButtonText: "确定",
            confirmButtonColor: "#F52735",
          });
          this.getBankCardInfo(); // 刷新卡片状态
          return;
        }

        // 其他支付方式
        if (res.data?.payUrl) {
          window.location.href = res.data.payUrl;
        }
      } catch (err) {
        this.$toast(err.msg || "支付失败");
      }
    },
  },
};
</script>

<style scoped lang="less">
.van-radio {
  margin-top: 8px;
}
</style>
